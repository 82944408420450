import { Card } from "react-bootstrap";
import { calculatePowerGeneration, calculateYearlyEvaporation, L_TO_ACRE_FEET_MULTIPLIER, readableAreaMetric, readablePower, readableVolumeMetric, sumAreas } from "../../utils/GeoUtils";
import { Project } from "../../utils/ProjectClient";
import { MapInfoBox, MapInfoBoxContainer } from "../../generic/mapping/MapBoxes";

function GeometryStatsInfoBoxInternal({ layers }: { layers: L.Layer[] }): JSX.Element {
  const area = layers ? sumAreas(layers) : 0;
  return (
    <MapInfoBox>
      <Card.Title>Estimated Stats:</Card.Title>
      <Card.Text>Area: {readableAreaMetric(area)}</Card.Text>
      <Card.Text>Power: {readablePower(calculatePowerGeneration(area))}</Card.Text>
      <Card.Text>Annual Evaporation Savings: {"~" + readableVolumeMetric(calculateYearlyEvaporation(area))} ({(calculateYearlyEvaporation(area) * L_TO_ACRE_FEET_MULTIPLIER).toFixed(0)} Acre-ft)</Card.Text>
    </MapInfoBox>
  );
}

export function GeometryStatsInfoBox({ layers }: { layers: L.Layer[] }): JSX.Element {
  return (
    <MapInfoBoxContainer>
      <GeometryStatsInfoBoxInternal layers={layers} />
    </MapInfoBoxContainer>
  );
}

export function ProjectInfoBox({ project }: { project: Project }): JSX.Element {
  return (
    <MapInfoBoxContainer>
      <GeometryStatsInfoBoxInternal layers={project.toFeatureGroup().getLayers()} />
      <MapInfoBox>
        <Card.Title>Project Info:</Card.Title>
        <Card.Text>Creator: {project.owner}</Card.Text>
        <Card.Text>Water Owner: {project.waterOwner}</Card.Text>
        <Card.Text>Water Owner Type: {project.waterOwnerType}</Card.Text>
        <Card.Text>Water Owner Contact Info: {project.waterOwnerContactInfo}</Card.Text>
        <Card.Text>Usage Type: {project.usageType}</Card.Text>
        <Card.Text>Border Type: {project.borderType}</Card.Text>
        <Card.Text>Waterbody Type: {project.waterbodyType}</Card.Text>
        <Card.Text>Classification: {project.classification}</Card.Text>
        <Card.Text>Security Type: {project.securityType.join(', ')}</Card.Text>
      </MapInfoBox>
    </MapInfoBoxContainer>
  );
}
