// Contains functions for querying the WFS server
// See https://docs.geoserver.org/latest/en/user/services/wfs/reference.html and https://schemas.opengis.net/wfs/2.0/examples/GetFeature/GetFeature_16.xml
// for some examples of sending WFS requests over the abomination called xml.
// More helpful links:
// https://www.datypic.com/sc/niem32/e-ns80_MultiSurface.html


import { MultiPolygon, Polygon } from 'geojson';
import { ApiClient } from './ApiClient';
import { CountyFeature } from '../types/governmentalunits.traits';
import { FlexibleFeatureCollection } from '../types/geojson';
import { create } from 'xmlbuilder2';
import { XMLBuilder } from 'xmlbuilder2/lib/interfaces';
import { NHDWaterbodyFeature } from '../types/nhdwaterbody.traits';

const WFS_PATH = 'geoserver/geonode/wfs'

export function polygonAsPosList(poly: Polygon): string {
  return poly.coordinates[0].map((c) => c[1] + ' ' + c[0]).join(' ');
}

export function createPolygonXmlElement(poly: Polygon): XMLBuilder {
  return create()
    .ele('gml:Polygon')
    .ele('gml:exterior')
    .ele('gml:LinearRing')
    .ele('gml:posList').txt(polygonAsPosList(poly)).root();
}

export function createGeometryXmlElement(poly: Polygon | MultiPolygon): XMLBuilder {
  switch (poly.type) {
    case 'Polygon':
      return createPolygonXmlElement(poly);
    case 'MultiPolygon':
      const members = create().ele('gml:MultiSurface').ele('gml:surfaceMembers');
      for (const member of poly.coordinates) {
        members.import(createPolygonXmlElement({ type: 'Polygon', coordinates: member }));
      }
      return members.root();
  }
}

export function createWithinFilter(poly: Polygon | MultiPolygon, geomField: string): XMLBuilder {
  return create().ele('fes:Filter').ele('fes:Within')
    .ele('fes:ValueReference').txt(geomField).up()
    .import(createGeometryXmlElement(poly))
    .root();
}

export function createIntersectsFilter(poly: Polygon | MultiPolygon, geomField: string): XMLBuilder {
  return create().ele('fes:Filter').ele('fes:Intersects')
    .ele('fes:ValueReference').txt(geomField).up()
    .import(createGeometryXmlElement(poly))
    .root();
}

export class WFSClient {
  apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async queryWFS<T = any>(query: XMLBuilder): Promise<T> {
    const queryXmlString = create({ version: '1.0' })
      .ele('GetFeature', { service: 'WFS', version: '2.0.0', outputFormat: 'application/json', strict: true })
      .att('xmlns:wfs', 'http://www.opengis.net/wfs/2.0')
      .att('xmlns:gml', 'http://www.opengis.net/gml/3.2')
      .att('xmlns:fes', 'http://www.opengis.net/fes/2.0')
      .import(query)
      .end({ prettyPrint: true });

    // We must send the content type as text/plain due to nestjs not being able to handle xml properly: the backend just needs to pass it along to geoserver, so pretend it is plaintext.
    return (await this.apiClient.axios().post<T>(WFS_PATH, queryXmlString, { headers: { 'Content-Type': 'text/plain' } })).data;
  }

  getCounties(poly: Polygon | MultiPolygon): Promise<FlexibleFeatureCollection<CountyFeature>> {
    return this.queryWFS<FlexibleFeatureCollection<CountyFeature>>(create().ele('Query', { typeNames: 'geonode:counties', srsName: 'EPSG:4326' })
      .import(createIntersectsFilter(poly, 'geometry')).up());
  }

  getNHDWaterbodies(poly: Polygon | MultiPolygon): Promise<FlexibleFeatureCollection<NHDWaterbodyFeature>> {
    return this.queryWFS<FlexibleFeatureCollection<NHDWaterbodyFeature>>(create().ele('Query', { typeNames: 'geonode:waterbodies', srsName: 'EPSG:4326' })
      .import(createWithinFilter(poly, 'geom')).up());
  }

  getNSRDBMetadata(poly: Polygon | MultiPolygon): Promise<any> {
    return this.queryWFS(create().ele('Query', { typeNames: 'geonode:nsrdb_metadata', srsName: 'EPSG:4326' })
      .import(createWithinFilter(poly, 'geometry')).up());
  }
}
