import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export function SearchBox(): JSX.Element {
  const map = useMap();

  useEffect(() => {
    const sc = GeoSearchControl({
      provider: new OpenStreetMapProvider(),
      style: 'bar',
      showMarker: true,
      showPopup: false,
      autoClose: true,
      retainZoomLevel: false,
      animateZoom: true,
      keepResult: false,
      searchLabel: 'search',
    });

    sc.addTo(map);

    return () => {sc.remove()};
  }, [map]);
  return <></>;
}
