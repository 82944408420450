import { ControlPosition } from 'leaflet';
import { useCookies } from 'react-cookie';
import { LayersControl, TileLayer, WMSTileLayer, useMap } from 'react-leaflet';

const SELECTED_BACKGROUND_LAYERS_COOKIE_NAME = 'background_layers';
export const USGS_ATTR = '<a href="https://www.usgs.gov/">U.S. Geological Survey</a> | <a href="https://www.usgs.gov/laws/policies_notices.html">Policies</a>'

// USGS Code based off of https://waterdata.usgs.gov/blog/basemaps/
export function getUSGSURL(service: string, host = 'basemap.nationalmap.gov'): string {
  return `https://${host}/arcgis/services/${service}/MapServer/WmsServer`
}

export function BackgroundLayersControl({ position }: { position: ControlPosition }): JSX.Element {
  const map = useMap();
  const [cookies, setCookie] = useCookies([SELECTED_BACKGROUND_LAYERS_COOKIE_NAME]);

  map.addEventListener('baselayerchange', (e) => {
    setCookie(SELECTED_BACKGROUND_LAYERS_COOKIE_NAME, e.name, { path: '/' });
  })

  return (
    <LayersControl position={position} >
      <LayersControl.BaseLayer name='OSM' checked={cookies.background_layers === 'OSM'}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          maxNativeZoom={18}
          maxZoom={map.getMaxZoom()}  // Must be set in order for autoscaling to work. See https://github.com/Leaflet/Leaflet/issues/4034
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name='OpenTopo' checked={cookies.background_layers === 'OpenTopo'}>
        <TileLayer
          url='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
          attribution='Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)'
          maxNativeZoom={17}
          maxZoom={map.getMaxZoom()}
        />
      </LayersControl.BaseLayer>
      
      <LayersControl.BaseLayer name='Clarity' checked={cookies.background_layers === 'Clarity'}>
      <TileLayer
        attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
        className="basemap"
        maxNativeZoom={17}
        maxZoom={map.getMaxZoom()}
        subdomains={["clarity"]}
        url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      />
      </LayersControl.BaseLayer>

      {/* <LayersControl.BaseLayer name='google'>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                    subdomains={['mt0','mt1','mt2','mt3']}
                />
            </LayersControl.BaseLayer> */}

      <LayersControl.BaseLayer name='USGS Topo' checked={cookies.background_layers === 'USGS Topo'}>
        <WMSTileLayer url={getUSGSURL('USGSTopo')} layers='0' attribution={USGS_ATTR} maxNativeZoom={16} maxZoom={map.getMaxZoom()} />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name='USGS Imagery' checked={cookies.background_layers === 'USGS Imagery' || !cookies.background_layers}>
        <WMSTileLayer url={getUSGSURL('USGSImageryOnly')} layers='0' attribution={USGS_ATTR} maxNativeZoom={16} maxZoom={map.getMaxZoom()} />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name='USGS Imagery Topo' checked={cookies.background_layers === 'USGS Imagery Topo'}>
        <WMSTileLayer url={getUSGSURL('USGSImageryTopo')} layers='0' attribution={USGS_ATTR} maxNativeZoom={16} maxZoom={map.getMaxZoom()} />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name='USGS Shaded' checked={cookies.background_layers === 'USGS Shaded'}>
        <WMSTileLayer url={getUSGSURL('USGSShadedReliefOnly')} layers='0' attribution={USGS_ATTR} maxNativeZoom={16} maxZoom={map.getMaxZoom()} />
      </LayersControl.BaseLayer>
    </LayersControl>
  );
};
