import React, { useEffect, useRef } from "react";
import { MapInfoBox, MapInfoBoxContainer } from "./MapBoxes";
import { ArrowsAngleContract, MapFill } from "react-bootstrap-icons";
import { Pane, WMSTileLayer } from "react-leaflet";
import { getUSGSURL, USGS_ATTR } from "./BackgroundLayersControl";
import { WaterbodyMarkerList } from "../../components/discovery/WaterbodyMarkerList";
import { Form, Table } from "react-bootstrap";
import { ControlPosition, DomEvent } from "leaflet";
import { controlPositionToClassnames } from "./LeafletHelpers";
import { useCookies } from "react-cookie";
import { LoggedInUserContext } from "../../App";
import { REMOTE_API_URL } from "../../constants";

const SELECTED_POLYGON_LAYERS_COOKIE_NAME = 'polygon_layers';
const SELECTED_OVERLAY_LAYERS_COOKIE_NAME = 'overlay_layers';
const DEFAULT_ENABLED_POLYGON_LAYERS = ['usgsHydroOld', 'usgsGovUnits', 'waterbodies']
const DEFAULT_ENABLED_OVERLAY_LAYERS = ['none']

// Required in order for overlays to show up over the background
const PANE = 'overlayPane';

export class DataLayer {
  name: string;
  element: JSX.Element;
  requiresLogin: boolean;


  constructor(name: string, element: JSX.Element, requiresLogin: boolean = false) {
    this.name = name;
    this.element = element;
    this.requiresLogin = requiresLogin;
  }
}

export const POLYGON_LAYERS: { [key: string]: DataLayer } = {
  usgsHydroOld: new DataLayer('USGS Hydrography Old', (<WMSTileLayer pane={PANE} url={getUSGSURL('USGSHydroCached')} layers='0' attribution={USGS_ATTR} format='image/png' transparent />)),
  usgsHydro: new DataLayer('USGS Hydrography', (<WMSTileLayer pane={PANE} url={getUSGSURL('nhd', 'hydro.nationalmap.gov')} layers='0' attribution={USGS_ATTR} format='image/png' transparent />)), // lower quality for some reason
  usgsHydroPlus: new DataLayer('NHDPlus', (<WMSTileLayer pane={PANE} url={getUSGSURL('NHDPlus_HR', 'hydro.nationalmap.gov')} layers='0' attribution={USGS_ATTR} format='image/png' transparent />)),
  usgsGovUnits: new DataLayer('USGS Borders', (<WMSTileLayer pane={PANE} url={getUSGSURL('govunits', 'carto.nationalmap.gov')} layers='0' attribution={USGS_ATTR} format='image/png' transparent />)),

  waterbodies: new DataLayer('Waterbodies', (<WaterbodyMarkerList />), true),
  powerTransmissionLines: new DataLayer('Powerlines', (<WMSTileLayer pane={PANE} url={REMOTE_API_URL + 'geoserver/geonode/wms'} layers='us_electric_power_transmission_lines__3845977064911730921' format='image/png' transparent />), true),
  nhdWaterbodies: new DataLayer('Waterbodies Poly', (<WMSTileLayer pane={PANE} url={REMOTE_API_URL + 'geoserver/geonode/wms'} layers='waterbodies' format='image/png' transparent />), true),
}

const NSRDB_FIELDS = ['elevation', 'local_time_zone', 'temperature_max', 'relative_humidity_max', 'dhi_max', 'ghi_max', 'dni_max', 'clearsky_dhi_max', 'clearsky_ghi_max', 'clearsky_dni_max', 'wind_speed_max', 'wind_direction_max', 'pressure_max', 'temperature_min', 'relative_humidity_min', 'dhi_min', 'ghi_min', 'dni_min', 'clearsky_dhi_min', 'clearsky_ghi_min', 'clearsky_dni_min', 'wind_speed_min', 'wind_direction_min', 'pressure_min', 'temperature_mean', 'relative_humidity_mean', 'dhi_mean', 'ghi_mean', 'dni_mean', 'clearsky_dhi_mean', 'clearsky_ghi_mean', 'clearsky_dni_mean', 'wind_speed_mean', 'wind_direction_mean', 'pressure_mean']
export const OVERLAY_LAYERS: { [key: string]: DataLayer } = {
  none: new DataLayer('None', (<div />))
}
for (const field of NSRDB_FIELDS) {
  OVERLAY_LAYERS[field] = new DataLayer(field.replaceAll('_', ' '), (<WMSTileLayer url={REMOTE_API_URL + 'geoserver/geonode/wms'} layers={"nsrdb_" + field} format='image/png' transparent />), true);
}

function LayersSelector({ layerDefinitions, checkedLayersCallback, disabledLayers, checkedLayers, radio = false }: { layerDefinitions: { [key: string]: DataLayer }, checkedLayersCallback: (layers: string[]) => void, disabledLayers?: DataLayer[], checkedLayers: string[], radio?: boolean }) {
  const user = React.useContext(LoggedInUserContext);
  return (
    <Table striped bordered hover>
      <tbody>
        {
          Object.entries(layerDefinitions)
            .filter(([_, layer]) => !disabledLayers?.includes(layer)) // Don't show disabled layers as an option
            .filter(([_, layer]) => !layer.requiresLogin || user)
            .map(([id, layer]) => (
              <tr key={id}>
                <td>{layer.name}</td>
                <td>
                  {radio ?
                    <Form.Check
                      id={id}
                      type="radio"
                      name="overlay_layers"
                      checked={checkedLayers?.includes(id)}
                      onChange={(e) => checkedLayersCallback([e.target.id])} />
                    :
                    <Form.Switch
                      id={id}
                      checked={checkedLayers?.includes(id)}
                      onChange={(e) => checkedLayersCallback(e.target.checked ? [...(checkedLayers), e.target.id] : checkedLayers.filter((id) => id !== e.target.id))} />}
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  )
}

export function DataLayersControl({ disabledLayers, position = 'topright' }: { disabledLayers?: DataLayer[], position?: ControlPosition }) {
  const [expanded, setExpanded] = React.useState(false);
  const [cookies, setCookie] = useCookies([SELECTED_POLYGON_LAYERS_COOKIE_NAME, SELECTED_OVERLAY_LAYERS_COOKIE_NAME]);

  const checkedPolygonLayers: string[] = cookies.polygon_layers || DEFAULT_ENABLED_POLYGON_LAYERS;
  const checkedOverlayLayers: string[] = cookies.overlay_layers || DEFAULT_ENABLED_OVERLAY_LAYERS;

  // Prevents clicks on this element from propagating to the map
  const divContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (divContainer.current) {
      DomEvent.disableClickPropagation(divContainer.current);
      DomEvent.disableScrollPropagation(divContainer.current);
    }
  });

  return (
    <div ref={divContainer}>
      <MapInfoBoxContainer className={controlPositionToClassnames(position)}>
        <MapInfoBox style={{}}>
          {
            expanded ?
              (<>
                <ArrowsAngleContract size={22} onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} />
                <div className="overflow-auto" style={{ maxHeight: '250px' }}> {/* TODO: Height shouldnt be static */}
                  <h4>Layers</h4>
                  <LayersSelector layerDefinitions={POLYGON_LAYERS} checkedLayersCallback={(layers) => setCookie(SELECTED_POLYGON_LAYERS_COOKIE_NAME, layers, { path: '/' })} disabledLayers={disabledLayers} checkedLayers={checkedPolygonLayers} />
                  <h4>Overlays</h4>
                  <LayersSelector layerDefinitions={OVERLAY_LAYERS} checkedLayersCallback={(layers) => setCookie(SELECTED_OVERLAY_LAYERS_COOKIE_NAME, layers, { path: '/' })} disabledLayers={disabledLayers} checkedLayers={checkedOverlayLayers} radio />
                </div>
              </>)
              :
              <MapFill size={32} onClick={() => setExpanded(true)} style={{ cursor: 'pointer' }} />
          }
        </MapInfoBox>
      </MapInfoBoxContainer>
      {
        checkedPolygonLayers
          .filter((id) => POLYGON_LAYERS.hasOwnProperty(id))  // Filter out layers that don't exist
          .filter((id) => !disabledLayers?.includes(POLYGON_LAYERS[id]))
          .map((id) => <div key={id}>{POLYGON_LAYERS[id].element}</div>)
      }
      <Pane name={"rasterPane"} style={{ zIndex: 300 }} >
        {
          checkedOverlayLayers
            .filter((id) => OVERLAY_LAYERS.hasOwnProperty(id))  // Filter out layers that don't exist
            .filter((id) => !disabledLayers?.includes(OVERLAY_LAYERS[id]))
            .map((id) => <div key={id}>{OVERLAY_LAYERS[id].element}</div>)
        }
      </Pane>
    </div>
  );
}
