import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { useLoaderData, useNavigate } from 'react-router';
import { Project } from '../../utils/ProjectClient';
import { FileEarmarkRuled, PencilSquare, Trash } from 'react-bootstrap-icons';
import { useSubmit } from 'react-router-dom';

type ProjectBrowserSidebarProps = {
  map: React.RefObject<L.Map>,
  projectSelectionHandler: (project: Project) => void
}

export default function ProjectBrowserSidebar({ map, projectSelectionHandler }: ProjectBrowserSidebarProps): JSX.Element {
  const projects = useLoaderData() as Project[];
  const navigate = useNavigate();
  const submit = useSubmit();

  return (
    <>
      <ButtonGroup>
        <Button variant='primary' onClick={() => navigate('/projects/new')} className='mb-2'>New</Button>
        <Button variant='secondary' onClick={() => navigate('/projects/table')} className='mb-2'>Table View</Button>
      </ButtonGroup>
      <Table striped bordered hover>
        <tbody>
          {projects.sort((a, b) => a.name.localeCompare(b.name)).map((project, index) => (
            // Sorted alphabetically
            <tr role='button'
              key={index}
              onClick={() => {
                projectSelectionHandler(project);
              }}>
              <td>{project.name} </td>
              <td><FileEarmarkRuled title='Report' onClick={(e) => { navigate('/projects/' + project.id + '/report'); e.stopPropagation() }} /></td>
              <td><PencilSquare title='Edit' onClick={(e) => { navigate('/projects/' + project.id + '/edit'); e.stopPropagation() }} /></td>
              <td><Trash title='Delete' onClick={(e) => {
                if (window.confirm('Are you sure you want to delete project "' + project.name + '"?')) {
                  submit(null, {
                    method: 'delete',
                    action: '/projects/' + project.id,
                  })
                }
                e.stopPropagation();
              }} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
