import { Col, Row } from 'react-bootstrap';
import { MapContainer, ZoomControl } from "react-leaflet"
import { BackgroundLayersControl } from "../../generic/mapping/BackgroundLayersControl"
import styles from '../../generic/mapping/Map.module.css';
import { MapPosition, MapPositionTracker } from '../../generic/mapping/MapPosition';
import { DataLayersControl } from '../../generic/mapping/DataLayersControl';
import { SearchBox } from '../../generic/mapping/SearchBox';

export function LandingMap() {

  // See App.tsx for class definitions
  return (
    <Row className='flex-fill flex-grow-1'>
      <Col className='gx-0'>
        <MapContainer
          center={MapPosition.coords}
          zoom={MapPosition.zoom}
          maxZoom={25}
          zoomSnap={0.5}
          scrollWheelZoom={true}
          className={styles['map']}
          zoomControl={false}>
          <ZoomControl position='bottomright' />
          <BackgroundLayersControl position='bottomright' />
          <MapPositionTracker />
          <DataLayersControl />
          <SearchBox />
        </MapContainer>
      </Col>
    </Row>
  );
}
